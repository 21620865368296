import React from 'react'
import './imgPost.scss'
import CatName from '../common/catName/CatName'
import Heading from '../common/catName/Heading'

const ImgPost = ({news, description, category}) => {
  console.log('description',description, news)
  return (
    <div className={`m-20 img-post box-shadow ${category? 'ct-post': null}`}>
        <img src={news?.image} alt='' />
        <CatName name={news?.cate_name} />
        <Heading slug={news?.id} title={news?.heading} />
        {description ? <p>{news?.description}</p> : null}
    </div>
  )
}

export default ImgPost