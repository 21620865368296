import React, { useEffect, useState } from 'react'
import '../styles/category.scss'
import ImgPost from '../component/imgPost/ImgPost'
import {useParams, useSubmit} from 'react-router-dom'
import { getCategorywisePost } from '../service/Service'

const Category = () => {
  const {slug} = useParams()
  console.log(slug)
  const [categoryPost, setCategoryPost] = useState([])
  useEffect(()=>{
    const getCategoryPost = async () => {
      try{
        const categoryPost = await getCategorywisePost(slug)
        setCategoryPost(categoryPost?.data)
      }catch(error){
        console.log(error)
      }
    }
    getCategoryPost()
  },[slug])
  return (
    <div className='category-pg'>
      {categoryPost.map((item, key)=>(
        <ImgPost news={item} category={true} description='true' />
      ))}
    </div>
  )
}

export default Category