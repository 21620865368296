import logo from './logo.svg';
import './App.css';
import Header from './component/Header/Header';
import Post from './component/post/Post';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './component/Footer/Footer';
import TermsAndCondition from './pages/TermsAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';
import Category from './pages/Category';
import NewsPage from './pages/NewsPage';
import Contact from './pages/Contact';
import SearchResults from './pages/SearchResult';

function App() {
  return (
    <>
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/category/:slug' element={<Category />} />
      <Route path='/terms_and_conditions' element={<TermsAndCondition />}/>
      <Route path='/privacy_policy' element={<PrivacyPolicy />} />
      <Route path='/about_us' element={<About />} />
      <Route path='/:slug' element={<NewsPage />}/>
      <Route path='/contact_us' element={<Contact />} />
      <Route path='/search-results' element={<SearchResults />}/>
    </Routes>
    <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
