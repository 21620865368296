import React,{useEffect, useState} from 'react'
import { privacypolicy } from '../service/Service'

const PrivacyPolicy = () => {
  const [ppData, setPpData] = useState()
  useEffect(()=>{
    const privacyPolicy = async() =>{
      try {
        const data = await privacypolicy()
        setPpData(data?.data.content)
        console.log('pp', ppData)
      } catch (error) {
        
      }
    }
    privacyPolicy()
  },[])
  return (
    <div className='m-40'>
    <div dangerouslySetInnerHTML={{__html: ppData}}/>
    </div>
  )
}

export default PrivacyPolicy