import React,{useEffect, useState} from "react";
import "../styles/newspage.scss";
import Post from "../component/post/Post";
import {useParams} from 'react-router-dom'
import { getCategorywisePost, newsApi } from "../service/Service";

const NewsPage = () => {
  const {slug} = useParams()
  const [newsData, setNewsData] = useState()
  const [cat, setCat] = useState('')
  const [catNews, setCateNews] = useState([])
  useEffect(()=>{
    const getNews = async() =>{
      try {
        const data = await newsApi(slug)
        setNewsData(data?.data)
        setCat(data?.data?.cate_name)
        console.log(data, 'newsPg')
      } catch (error) {
        
      }
    }
    getNews()
  },[slug])

  useEffect(() => {
    const catNews = async() => {
      try{
        const response = await getCategorywisePost(cat)
        console.log('res', response)
        const toShow = response?.data.slice(0,3)
        console.log('toShow',toShow)
        setCateNews(toShow)
      } catch(err) {
        console.log(err)
      }
    }
    catNews()
  },[cat])
  return (
    <div className="news-pg-container">
    <div className="news-page">
      <div className="image-container">
        <img
          src="https://themedox.com/posty/wp-content/uploads/2024/01/office-task-803x490.jpg"
          alt="Descriptive Alt Text"
          className="background-image"
        />
        <h5 className="image-heading">
          {newsData?.heading}
        </h5>
      </div>
      <div className="content-c m-20">
        <div className="content">
          <p>
            {newsData?.description}
          </p>
          </div>
        {/* <div className="latest-post">
        <Post />
        <Post />
        <Post />
      </div> */}
      </div>
    </div>
    <div className="related-news">
      <h5>Related News</h5>
      { catNews.map((item, key)=>(
        <Post news={item} />
      ))}
      </div>
    </div>
  );
};

export default NewsPage;
