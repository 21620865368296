import React,{ useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom';
import { useContext } from 'react'
import { CategoryContext } from '../../context/categoryContext'
import { NavLink } from 'react-router-dom';

const Copyright = () => {
    const date = new Date();
  const year = date.getFullYear();
  const category = useContext(CategoryContext)
  const [categoryList, setCategoryList] = useState([])
  // const navigate=useNavigate()
  useEffect(() =>{
    setCategoryList(category)
},[category])
  return (
    <div className='bottom-footer p-20'>
        <div className="copyright">
      Copyright © {year} Newsthan Ltd All Right Reserved
    </div>
    <ul className="footer_nav">
    <li><NavLink to='/about_us'>About Us</NavLink></li> {/* Use NavLink */}
                <li><NavLink to='/contact_us'>Contact Us</NavLink></li> {/* Update this if you have a route */}
                <li><NavLink to='/terms_and_conditions'>Terms of Use</NavLink></li>
                <li><NavLink to='/privacy_policy'>Privacy Policy</NavLink></li>
    </ul>
    </div>
  )
}

export default Copyright