import React, { useEffect, useState } from 'react'
import './footer.scss'
import { useContext } from 'react'
import { CategoryContext } from '../../context/categoryContext'
import Copyright from './Copyright'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  const category = useContext(CategoryContext)
  const [categoryMenu, setCategoryMenu] = useState([])
  useEffect(()=>{
    setCategoryMenu(category)
  },[category])
  return (
    <div className='footer'>
    <div className='footer-section p-20'>
      <div className='logo'>
        <img src={`${process.env.PUBLIC_URL}/assets/img/newsthan.png`} />
      </div>
      <div className='categories'>
        <h6>CATEGORIES</h6>
        {categoryMenu && categoryMenu.map((item, index)=>
        // <p><a key={index} href={`/category/${item?.name}`} style={{textDecoration:'none', color:'white'}}>{item?.name}</a></p>
        <p><NavLink to={`/category/${item?.name}`} style={{textDecoration:'none', color:'white'}}>{item?.name}</NavLink></p>
        )
        }
      </div>
      <div className='help'>
        <h6>NEED HELP</h6>
        <div>
          <p className='heading'>Email for us</p>
          <a href='mailto:contact@newsthan.com'>contact@newsthan.com</a>
        </div>
        {/* <div>
          <p className='heading'>Phone for us</p>
          <a href='tel:0000000000000'>+91 9876543210</a>
        </div> */}
      </div>
    </div>
    <Copyright />
    </div>
  )
}

export default Footer