import React, { useEffect, useState, useContext, useRef } from "react";
import TopHeader from "./TopHeader";
import "./header.scss";
import { CategoryContext } from "../../context/categoryContext";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { searchApi } from "../../service/Service";

const Header = () => {
  const category = useContext(CategoryContext);
  const [categoryList, setCategoryList] = useState([]);
  const [showCategory, setShowCategory] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [searchInput, setSearchInput] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate()
  const location = useLocation();  // Use to monitor path changes
  const previousLocation = useRef(location);

  useEffect(() => {
    // Function to check if it is navigating away from the search-results page
    const navigatingAwayFromSearchResults = previousLocation.current.pathname === '/search-results' && location.pathname !== '/search-results';

    if (navigatingAwayFromSearchResults) {
      setSearchInput(false); // Close the search input only when navigating away from the search-results page
    }

    // Update the previous location
    previousLocation.current = location;
  }, [location]);

  
  useEffect(() => {
    setCategoryList(category);
  }, [category]);
  const toggleSidebar = () => setShowSidebar(!showSidebar);
  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const handleSearchInput = () => {
    setSearchInput(!searchInput);
  };
  const searchApiHandle = async () => {
    const apiData = await searchApi(search);
    if(apiData?.data){
      navigate('/search-results', {
        state: { openSearch: true, data: apiData?.data, query: search } // Pass additional state and query as needed
    })
    }
  };
  return (
    <div>
      <TopHeader />
      <header className="header">
        {showSidebar && ( // Conditional rendering for sidebar
          <nav className="sidebar">
            <div className="close-sec">
              <button className="close-btn" onClick={toggleSidebar}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/cross.png`}
                  alt=""
                />
              </button>
            </div>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about_us">About</NavLink>
              </li>
              {/* Map through categories for the sidebar */}
              <li onClick={toggleCategories} className="t_cat">
                <p>Categories</p>
                <div
                  className={`arrow ${showCategories ? "up" : "down"}`}
                ></div>
              </li>
              {showCategories && (
                <ul className="nav-group group-2">
                  {category &&
                    categoryList.map((category, index) => (
                      <NavLink key={index} to={`/category/${category.name}`}>
                        {category.name}
                      </NavLink>
                    ))}
                </ul>
              )}
              <li>
                <NavLink to="contact_us">Contact</NavLink>
              </li>
            </ul>
          </nav>
        )}
        <nav className="desktop-nav">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about_us">About</NavLink>
            </li>
            <li
              className="categories-nav-item"
              onMouseEnter={() => setShowCategory(true)}
              onMouseLeave={() => setShowCategory(false)}
            >
              Categories
              {showCategory && (
                <div className="category-dropdown">
                  {categoryList.map((category, index) => (
                    <NavLink key={index} to={`/category/${category.name}`}>
                      {category.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </li>
            <li>
              <NavLink to="contact_us">Contact</NavLink>
            </li>
          </ul>
        </nav>
        <div className="search-toggle">
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            ☰
          </button>
          <button className="search" onClick={handleSearchInput}>
            <img src="assets/img/search.png" alt="" />
          </button>
        </div>
      </header>
      {searchInput && (
        <div className={`search_container ${searchInput ? "open" : "closed"}`}>
          <div>
            <input
              className="search_input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="search_btn" onClick={searchApiHandle}>
              <img src="assets/img/search.png" alt="" />
            </button>
          </div>
          <button
            className="close-search"
            onClick={() => setSearchInput(false)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/cross.png`}
              alt="Close"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
