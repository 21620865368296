import React from 'react'
// import logo from 'asset/img/newsthan.png'

const TopHeader = () => {
  return (
    <div className='top-header flex-center pv-5'>
      <img src={`${process.env.PUBLIC_URL}/assets/img/newsthan.png`} alt='' />
    </div>
  )
}

export default TopHeader