import React, { useEffect, useRef, useState } from "react";
import "../styles/termsandcondition.scss";
import { Link } from "react-router-dom";
import { termsconditions } from "../service/Service";

const TermsAndCondition = () => {
  const [termsAndCondition, setTermsAndCondition]=useState()
  useEffect(()=>{
  const tcondition = async() =>{
    try {
      const data = await termsconditions()
      console.log('123', data)
      setTermsAndCondition(data?.data.content)
    } catch (error) {
      
    }
  }
  tcondition()
  },[])
  return (
    <div className='m-40'>
    <div dangerouslySetInnerHTML={{__html: termsAndCondition}}/>
    </div>
  );
};

export default TermsAndCondition;
