import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Post from '../component/post/Post';
import '../styles/searchresult.scss'

const SearchResults = () => {
    const location = useLocation();  // Access location object
    const [results, setResults] = useState([]);
    const [query, setQuery] = useState('');
    const [openSearch, setOpenSearch] = useState(false);

    useEffect(() => {
        // Extract data from location state if available
        if (location.state) {
            console.log('data000', location)
            setResults(location.state.data);  // Assuming 'data' contains your search results
            setQuery(location.state.query);   // The search query passed
            setOpenSearch(location.state.openSearch);  // Flag to keep search bar open
        }
    }, [location.state]);

    return (
        <div className='search_result_container'>
            <h2>Search Results for: {query}</h2>
            <div className="results-list">
                {results.length > 0 ? (
                    results.map((item, index) => (
                        <div className='result'>
                        <Post news={item} />
                        </div>
                    ))
                ) : (
                    <p>No results found.</p>
                )}
            </div>
        </div>
    );
};

export default SearchResults;
