import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
    "Content-Type": "application/json",
  },
});
console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)

export const getCategory = async () => {
  try {
    console.log('Hi', process.env.REACT_APP_API_URL)
    const response = await API.get("/get_category");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch category:", error);
    throw error;
  }
};

export const bannerApi = async () => {
  try {
    const response = await API.get("/get_banner");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch category:", error);
    throw error;
  }
};

export const newsApi = async (id) => {
  console.log('id1234567890', id)
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
      },
    };
    const response = await axios.post(
      "https://newsthan.com/admin/api/post_withoutlogin_details",
      {
        postId: id,
      },
      config
    );
    console.log(response.data, "fetchNews");
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
  }
};

export const searchApi = async (param) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
      },
    };
    const response = await axios.get(
      `https://newsthan.com/admin/api/all_withoutlogin_post?skip=0&limit=10&keyword=${param}`
    )
    return response.data
    console.log('search', response)
  } catch (error) {
    
  }
}

export const getNewsHome = async () => {
  try {
    const response = await API.get(
      "https://newsthan.com/admin/api/get_post?skip=10&limit=10"
    );
    console.log("1234569012345678909", response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch category:", error);
    throw error;
  }
};

export const getCategorywisePost = async (category) => {
  console.log('category', category)
  try {
    const response = await API.get(
      `https://newsthan.com/admin/api/get_post?skip=10&limit=10&category=${category}`
    );
    console.log("1234569012345678909", response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch category:", error);
    throw error;
  }
};

export const privacypolicy = async () => {
  try {
    const response = await API.get("/privacy_policy");
    return response.data;
    console.log("pp", response);
  } catch (error) {
    console.log("pp", error);
    throw error;
  }
};

export const termsconditions = async () => {
  try {
    const response = await API.get("/terms_conditions");
    return response.data;
    console.log("pp", response);
  } catch (error) {
    console.log("pp", error);
    throw error;
  }
}

export const aboutUs = async () => {
  try {
    const response = await API.get("/about_us");
    return response.data;
    console.log("pp", response);
  } catch (error) {
    console.log("pp", error);
    throw error;
  }
}
