import React from 'react'
import './post.scss'
import Heading from '../common/catName/Heading'

const Post = ({news}) => {
  return (
    <div className='post box-shadow'>
        <img src={news?.image} alt='' />
        <div className='post-details'>
            <p>{news?.cate_name}</p>
           <Heading slug={news?.id} title={news?.heading} />
        </div>
    </div>
  )
}

export default Post