import React, { useEffect, useState } from "react";
import Post from "../component/post/Post";
import "../styles/home.scss";
import ImgPost from "../component/imgPost/ImgPost";
import Banner from "../component/banner/Banner";
import EditorPostCard from "../component/editorPost/EditorPostCard";
import MainHeading from "../component/common/MainHeading";
import { getCategorywisePost, getNewsHome } from "../service/Service";

const Home = () => {
  const [news, setNews] = useState([]);
  const [business, setBusiness] = useState([])
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const news = await getNewsHome();
        setNews(news.data);
        console.log("news are: ", news);
      } catch (error) {
        // console.log()
      }
    };
    fetchNews();
  }, []);

  useEffect(()=>{
    const fetchBusiness = async()=>{
      try{
        const data = await getCategorywisePost('business')
        console.log('business', data)
        setBusiness(data?.data)
      }catch(error){}
    }
    fetchBusiness()
  },[])
  const editorPost = news.length > 0? news[0] : null
  return (
    <div>
      <div className="main-banner m-40-20">
        {news.slice(1, 4).map((item, key) => (
          <Post news={item} />
        ))}
        {/* <Post />
            <Post />
            <Post /> */}
      </div>
      <div className="highlight-slider">
        <div className="i-post-container">
          {news.slice(5, 7).map((item, key) => (
            <ImgPost news={item} />
          ))}
          {/* <ImgPost />
          <ImgPost /> */}
        </div>
        <div className="banner-container">
          <Banner />
        </div>
      </div>
      <div className="m-40-20">
        {/* {news.slice(7)(item, key) => ( */}
          <EditorPostCard data={editorPost} />
        {/* ))} */}
      </div>
      <div className="post-section-cat m-40-20">
        <MainHeading />
        <div className="i-post-container-main">
          {business.map((item, key)=>(
            <ImgPost news={item} description={false} category={true} />
          ))}
          {/* <div className="i-post-container">
            <ImgPost />
            <ImgPost />
          </div>
          <div className="i-post-container">
            <ImgPost />
            <ImgPost />
          </div>
          <div className="i-post-container">
            <ImgPost />
            <ImgPost />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
