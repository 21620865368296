import React,{useEffect, useState} from 'react';
import Slider from 'react-slick';
import './banner.scss'
import { bannerApi } from '../../service/Service';

const Banner = () => {
  // Settings for the slider
  const [banners, setBanners] = useState([])
  const settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(()=>{
    const getBanner = async() =>{
      try {
        const data = await bannerApi()
        console.log('banner', data)
        setBanners(data?.data)
      } catch (error) {
        console.log(error)
      }
    }
    getBanner()
  },[])

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {
          banners.map((banner, key)=>(
            <div className="banner-img-wrapper" key={key}>
      <img src={banner.image} alt={`Banner ${key}`} className="banner-img" />
      <h2 className="banner-heading">{banner.heading}</h2>
      </div>
          ))
        }
        {/* Add as many slides as you need */}
      </Slider>
    </div>
  );
};

export default Banner;
