import React from 'react';
import './editorPost.scss'
import Heading from '../common/catName/Heading';
import CatName from '../common/catName/CatName';

const EditorPostCard = ({data}) => {
  return (
    <div className='editor-card'>
        <div className='post-card-img'>
            <img src={'https://themedox.com/posty/wp-content/uploads/2024/01/pexels-kampus-production-6180396.jpg'} alt='' />
        </div>
        <div className='post-content'>
            <CatName name={data?.cate_name} />
            <Heading title={data?.heading} />
        </div>
    </div>
  )
}

export default EditorPostCard