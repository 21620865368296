import React, { createContext, useEffect, useState } from "react";
import { getCategory } from "../service/Service"

export const CategoryContext = createContext();

const ContextCategory = ({ children }) => {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    console.log('Hi')
    const fetchCategory = async () => {
      try {
        const data = await getCategory();
        setCategory(data.data);
      } catch (error) {
        console.error("Error Fetching Category", error);
      }
    };
    fetchCategory();
  }, []);
  return (
    <CategoryContext.Provider value={category}>
      {children}
    </CategoryContext.Provider>
  );
};

export default ContextCategory;
