import React,{useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { newsApi } from '../../../service/Service'

const Heading = ({slug, title}) => {
  useEffect(()=>{
    const getPost = async() =>{
      try {
        const data = await newsApi(slug)
        console.log('data page', data)
      } catch (error) {
        
      }
    }
    getPost()
  },[slug])
  return (
    <h5 className='heading-underline'><NavLink to={`/${slug}`} style={{ textDecoration: 'none', color: 'inherit' }}><span>{title}</span></NavLink></h5>
  )
}

export default Heading