import React, { useEffect, useState } from 'react'
import { aboutUs } from '../service/Service'

const About = () => {
  const [aboutData, setAboutData]=useState()
  useEffect(()=>{
    const about = async() =>{
      try {
        const data =await aboutUs()
        console.log('au', data)
        setAboutData(data?.data.content)
      } catch (error) {
        
      }
    }
    about()
  },[])
  return (
    <div className='m-40'>
    <div dangerouslySetInnerHTML={{__html: aboutData}}/>
    </div>
  )
}

export default About