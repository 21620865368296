import React from "react";
import './../styles/contact.scss'

const Contact = () => {
    return(
        <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <p><span className="info-label">Email:</span> <a href="mailto:contact@newsthan.com">contact@newsthan.com</a></p>
        <p><span className="info-label">Phone:</span> <a href="tel:+919101282676">+91 9101282676</a></p>
        <p><span className="info-label">Address:</span> <span>HOUSE NO- 1283K, Room no5, SOUTH TOKRERCHARA PT-4, Golakganj, Dhubri, Assam, India - 783334</span></p>
      </div>
    </div>
    )
}
export default Contact